var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticStyle: { "max-height": "300px", "overflow-y": "auto" } },
    [
      this.$store.state.communicate.isProcessing
        ? _c("span", { staticClass: "u-blue-text font-weight-bold" }, [
            _vm._v(" " + _vm._s(_vm.$t("LoadingMessages")) + " "),
          ])
        : _vm._e(),
      _vm._l(this.messages, function (item) {
        return _c(
          "v-layout",
          {
            key: item.id,
            staticClass: "u-blue-text pt-2 pb-2 pl-2 pr-2 w-100",
          },
          [
            _c("i", { staticClass: "mdi mdi-account-multiple" }),
            _c(
              "div",
              { staticClass: "pl-2" },
              [
                _c("v-layout", { staticClass: "font-weight-medium" }, [
                  _vm._v(
                    " " +
                      _vm._s(item.name) +
                      " " +
                      _vm._s(_vm.dateFormatted(item.date)) +
                      " "
                  ),
                ]),
                _vm._v(" " + _vm._s(item.message) + " "),
              ],
              1
            ),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }