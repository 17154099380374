var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "mb-0 pt-0 pb-0" },
    [
      _c("message-list", { attrs: { messages: _vm.messages } }),
      _c(
        "v-row",
        [
          _c("v-textarea", {
            staticClass: "mt-2",
            attrs: {
              clearable: "",
              placeholder: _vm.$t("MessageRequired"),
              height: "60",
              "no-resize": true,
              dense: "",
              outlined: "",
            },
            model: {
              value: _vm.textMessage,
              callback: function ($$v) {
                _vm.textMessage = $$v
              },
              expression: "textMessage",
            },
          }),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              staticClass: "white--text u-background-primary",
              on: { click: _vm.sendMessage },
            },
            [_vm._v(" " + _vm._s(_vm.$t("SendSmall")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }